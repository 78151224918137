.Outer {
  padding: 0;
}
.Header {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
  padding-bottom: 10px;
}

.Toggle {
  cursor: pointer;
  padding: 4px 8px;
  margin: 4px 0;
  border: 2px solid var(--color-yellow);
}
.Toggled {
  cursor: pointer;
  padding: 4px 8px;
  border: 2px solid var(--color-yellow);
  border-bottom: none;
}
.ToggledContent {
  padding: 4px 8px;
  border: 2px solid var(--color-yellow);
  border-top: none;
  margin-bottom: 10px;
}

.ProfilePhoto {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23%;
}
.ProfilePhoto > .Image {
  width: 100%;
  height: 100%;
}

.TypeProOuter {
  padding: 10px 0;
}
