.Outer {
  height: 100vh;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Title {
  font-size: var(--fs-6);
  font-weight: 500;
}
.SubTitle {
  font-size: var(--fs-2);
  padding: 10px 0 100px 0;
  text-shadow: 1px 1px 1px var(--color-yellow);
}

@media (min-width: 450px) {
  .Title {
    font-size: var(--fs-9);
    font-weight: 500;
    text-align: center;
  }
  .SubTitle {
    font-size: var(--fs-4);
    text-align: center;
    margin: 10px auto;
    max-width: 600px;
    text-shadow: 1px 1px 1px var(--color-yellow);
  }
  .ScrollDown {
    height: 140px;
    width: 34px;
    cursor: pointer;
  }
  .Scroll {
    height: 60px;
    width: 100%;
    background-image: url("../../../../assets/svg/mouse.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    position: relative;
    bottom: 0;
    transition: 125ms;
  }
  .ScrollDown > .Arrow {
    height: 30px;
    width: 100%;
    background-image: url("../../../../assets/svg/down_arrow.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    position: relative;
    bottom: 0;
    transition: 250ms;
  }
  .ScrollDown:hover > .Scroll {
    bottom: -4px;
  }
  .ScrollDown:hover > .Arrow {
    bottom: -12px;
  }
}
