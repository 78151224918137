.ModalBackdrop {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(100, 100, 102, 0.25);
  position: fixed;
  top: 0;
  left: 0;
}
.Modal {
  padding: 10px 15px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 6px 12px 2px;
  background-color: white;
  border-radius: 3px;
  max-width: calc(100vw - 32px);
  min-width: 50vw;
  height: auto;
  max-height: 340px;
  position: absolute;
  top: 27vh;
  right: 16px;
}

@media (min-width: 450px) {
  .Modal {
    max-width: 410px;
    min-width: 300px;
    height: auto;
    max-height: 340px;
    top: 27vh;
    left: calc(50vw);
  }
}
