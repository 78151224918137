.LineTitle {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 16px;
  align-items: center;
}
.ExplanationUL {
  display: block;
  list-style-type: circle;
  margin-block-start: 10px;
  margin-block-end: 0;
  margin-inline-start: 40px;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
