.FooterOuter {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--color-yellow);
  border-top: var(--gray-border-match-yellow);
}

.FooterOuter > a {
  width: 50px;
  min-height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 76% 76%;
  cursor: pointer;
  text-decoration: none;
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}
.FooterOuter.FooterMobile {
  position: fixed;
}
.FooterOuter.FooterDesktop {
  position: relative;
}

.Public {
  background-size: 70% 70%;
  background-image: url("../../../assets/svg/public.svg");
}
.Public:hover {
  background-image: url("../../../assets/svg/public_on.svg");
}

.Work {
  background-image: url("../../../assets/svg/gym.svg");
}
.Work:hover {
  background-image: url("../../../assets/svg/gym_on.svg");
}

.Now {
  background-image: url("../../../assets/svg/now.svg");
}
.Now:hover {
  background-image: url("../../../assets/svg/now_on.svg");
}

.Stats {
  background-image: url("../../../assets/svg/stats.svg");
}
.Stats:hover {
  background-image: url("../../../assets/svg/stats_on.svg");
}

.User {
  background-image: url("../../../assets/svg/user.svg");
}
.User:hover {
  background-image: url("../../../assets/svg/user_on.svg");
}

.ActivePublic {
  background-image: url("../../../assets/svg/public_on.svg");
}
.ActiveWork {
  background-image: url("../../../assets/svg/gym_on.svg");
}
.ActiveNow {
  background-image: url("../../../assets/svg/now_on.svg");
}
.ActiveStats {
  background-image: url("../../../assets/svg/stats_on.svg");
}
.ActiveUser {
  background-image: url("../../../assets/svg/user_on.svg");
}

.Hidden {
  width: 0;
  height: 0;
  position: fixed;
  overflow: hidden;
}
.LoadPublic {
  background-image: url("../../../assets/svg/public_on.svg");
}
.LoadWork {
  background-image: url("../../../assets/svg/gym_on.svg");
}
.LoadNow {
  background-image: url("../../../assets/svg/now_on.svg");
}
.LoadStats {
  background-image: url("../../../assets/svg/stats_on.svg");
}
.LoadUser {
  background-image: url("../../../assets/svg/user_on.svg");
}

@media (min-width: 450px) {
  .FooterOuter {
    width: 100%;
    margin: auto;
    position: sticky !important;
    bottom: 0;
  }
}
