.IsHost {
  position: absolute;
  padding-left: 7px;
  color: var(--blue-dark-link);
  font-weight: 500;
  font-size: var(--fs-2);
}
.LabelName {
  text-align: center;
  font-size: var(--fs-2);
}
.LabelHost {
  font-weight: 500;
  font-size: var(--fs-small);
  color: var(--blue-dark-link);
}
