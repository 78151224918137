.rdrCalendarWrapper {
  width: 100%;
}
.rdrDateDisplayWrapper {
  background-color: transparent !important;
}
.rdrDateDisplay {
  margin: 0 !important;
  display: none !important;
}
.rdrDateDisplayItemActive input,
.rdrDateDisplayItem input {
  margin: 0;
}
.rdrMonthAndYearWrapper {
  margin: 0 0 7px 0 !important;
  height: auto !important;
  /* padding: 0 0 10px 0 !important; */
  /* border-bottom: var(--gray-border-match-yellow); */
}
.rdrMonthName {
  display: none !important;
}
.rdrNextPrevButton {
  margin: 0 !important;
  width: 40px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-inputs) !important;
}
.rdrNextPrevButton i {
  margin: 0 !important;
}
.rdrMonthPicker,
.rdrYearPicker {
  height: 38px !important;
  border-radius: var(--border-radius-big);
  background-color: var(--background-color-inputs) !important;
}
.rdrMonthPicker select,
.rdrYearPicker select {
  font-size: 1rem !important;
}
.rdrWeekDay {
  font-size: var(--fs-small) !important;
  color: var(--color-gray) !important;
}
.rdrDayNumber {
  font-weight: inherit !important;
  font-size: 1rem !important;
}
.rdrDayPassive .rdrDayNumber span {
  color: #a0a0a0 !important;
}
.rdrMonth {
  padding: 0 !important;
}
.rdrDayToday .rdrDayNumber span:after {
  bottom: 3px !important;
  height: 1.5px !important;
}
