.Outer {
  width: 100%;
  position: relative;
}
.RegularSplit {
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 10px 0;
}
.FixedTop {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  padding: 10px 16px;
  z-index: 999;

  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  box-shadow: var(--box-shadow-discret);
}

.Button {
  cursor: pointer;
  padding: 10px;
  font-size: var(--fs-small);
  background-color: var(--color-yellow);
  border-radius: var(--border-radius);
  border: none;
  text-align: center;
}
.Save {
  background-color: var(--background-blue);
  background-color: var(--color-black);
  color: white;
}
.Cancel {
  background-color: transparent;
  border: var(--blue-border);
  color: var(--blue-dark-text);
}

@media (min-width: 450px) {
  .FixedTop {
    position: relative;
  }
}
