.HeaderOuter {
  height: 50px;
  position: sticky;
  top: 0;
  padding: 0 var(--padding-regular);
  background-color: var(--color-black);
  color: var(--color-yellow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Content {
  padding: var(--padding-content);
}

@media (min-width: 450px) {
  :root {
    --desktop-width-outer: 400px;
    --desktop-width-inner: 367px;
    --desktop-height-outer: 729px;
    --desktop-height-inner: 702px;
  }

  .FooterOuter {
    width: 100%;
    margin: auto;
    position: sticky !important;
    bottom: 0;
  }
  .Outer {
    max-width: 1000px;
    margin: auto;
  }
  .DesktopExplanationsOuter {
    width: var(--desktop-width-outer);
    height: var(--desktop-height-outer);
    padding: 16px;
    padding: 32px 16px 16px 32px;
  }
  .Desktop_LandingSection_App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Desktop_Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 70px;
    height: 100vh;
  }
  .Desktop_Footer {
    max-width: 1000px;
    margin: 0 auto;
    padding: 32px 16px;
  }
  .Desktop_MobileApp_Outer {
    background-image: url("../../assets/png/frame.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: var(--desktop-height-outer);
    width: var(--desktop-width-outer);
    margin: auto;
    position: relative !important; /* do not remove */
    /* corectie pentru poza de rama: */
    padding: 14px 0 0 3px;
  }
  .Desktop_MobileApp_Inner {
    height: var(--desktop-height-inner);
    width: var(--desktop-width-inner);
    overflow: scroll;
    margin: auto;
    position: relative !important; /* do not remove */
    /* corectie pentru poza de rama: */
    background-color: white;
    border-radius: 15px;
  }
  /* HIDE SCROLL BARS */
  .Desktop_MobileApp_Outer,
  .Desktop_MobileApp_Inner {
    /* for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* for Chrome, Safari and Opera */
  .Desktop_MobileApp_Outer::-webkit-scrollbar,
  .Desktop_MobileApp_Inner::-webkit-scrollbar {
    display: none;
  }
  .Content {
    padding: 32px 16px;
    min-height: calc(var(--desktop-height-inner) - 54px);
  }
}
