* {
  box-sizing: border-box;
  /* font-size: var(--fs-1rem); */
}
@media (min-width: 450px) {
  body {
    background-image: url("../assets/png/bgr.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  #root {
    background-image: url("../assets/png/bgr_top.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
  }
}
p {
  word-break: break-word;
  color: black;
}
b,
strong {
  font-weight: 500 !important;
}
p,
ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
input,
textarea {
  background-color: var(--background-color-inputs);
  margin: 4px 0 14px 0;
  width: 100%;
  min-height: 40px;
  padding: 10px 14px;
  border: var(--gray-border-match-yellow);
  border-radius: var(--border-radius-big);
  line-height: 1.5;
}
.Radio {
  width: auto;
  min-height: auto;
  padding: 0;
  margin: 0;
}
label {
  font-size: var(--fs-small);
}
label > span {
  padding: 0 14px;
}
select {
  border: var(--gray-border-match-yellow);
  border-radius: var(--border-radius-big);
  padding: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ErrorParagraph {
  color: var(--color-red);
  padding: 4px 0;
}
.ErrorButton {
  color: var(--color-red);
  padding: 7px;
  border: var(--error-border);
  border-radius: var(--border-radius);
  text-align: center;
}
.Title {
  font-size: var(--fs-1);
  font-weight: 500;
  padding: 2px 0;
}
.Title_Big {
  font-size: var(--fs-2);
}
.Title_Bigger {
  font-size: var(--fs-4);
}
.Text_Gray {
  color: var(--color-gray);
}
.TextSecondary {
  font-size: var(--fs-small);
  color: var(--color-gray);
  padding: 2px 0;
}
.TextAlignCenter {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --desktop-min-width: 470px;

  --fs-small: 0.875rem;
  --fs-1rem: 1rem;
  --fs-1: 1.125rem;
  --fs-2: 1.25rem;
  --fs-3: 1.5rem;
  --fs-4: 1.75rem;
  --fs-5: 2rem;
  --fs-6: 3rem;
  --fs-7: 4rem;
  --fs-8: 5rem;
  --fs-9: 6rem;

  --padding-regular: 16px;
  --padding-content: 32px 16px 90px 16px;

  --blue: #4285f4;
  --blue-dark: #2b74ea;
  --blue-dark-link: #004aff;
  --blue-dark-text: #0031ae;

  --gray-border-match-yellow: 1px solid rgb(235, 235, 235);
  --gray-border: 1px solid lightgray;
  --error-border: 1px solid var(--color-red);
  --blue-border: 1px solid var(--blue-dark-link);
  --yellow-border: 1.5px solid antiquewhite;
  --border-blue: 1px solid var(--blue-dark);

  /* #fcff04 */
  /* #004aff */
  --color-yellow: rgb(255, 242, 103);
  --color-lightgray: rgb(173, 173, 173);
  --color-gray: rgb(103, 103, 103);
  --color-dark: rgb(47, 47, 47);
  --color-black: #000;
  --color-red: rgb(214, 0, 0);

  --box-shadow-discret: 0 1px 3px 0 rgb(0 0 0/7%);
  --box-shadow-reverse: 0 -1px 3px 0 rgb(0 0 0/7%);

  /* --background-color-inputs: rgba(226, 240, 255, 0.25); */
  --background-color-inputs: #f8f7f7;
  --background-blue: #5492f6;

  --border-radius: 3px;
  --border-radius-big: 7px;
}

.Bold {
  font-weight: 500;
}

.RowStartSpaceBetween {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.RowCenterSpaceBetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.RowStartStart {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.RowCenterStart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.RowCenterCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Grid2Columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.Link {
  color: var(--blue-dark-link);
  cursor: pointer;
  padding: 2px 0;
}
.Link:hover {
  text-decoration: underline;
}

/* BUTTON */
.Button {
  font-size: var(--fs-small);
  background-color: var(--color-yellow);
  border-radius: var(--border-radius);
  border: var(--gray-border-match-yellow);
  text-align: center;
  min-height: 40px;
  margin: 10px 0;
  width: 100%;
  color: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Button.Secondary {
  background-color: transparent;
  border: var(--blue-border);
  color: var(--blue-dark-text);
}
.Button:hover {
  color: black;
}
.Button.Black {
  background-color: black;
  color: white;
}
.Button.Black:hover {
  color: var(--color-yellow);
}

/* for EVENT & EVENT TYPES - LIST ELEMENT */
.EventListElement {
  padding: 8px 0;
  border-bottom: var(--border-blue);
  cursor: pointer;
}
.EventListElement:last-child {
  border-bottom: none;
}
.EventIconRow {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;
  align-items: center;
}
.EventIconRow > div {
  width: 24px;
  padding: 1px 0;
}
.EventIconRow > div > img {
  width: 18px;
}
.RowCenterSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.EventListElementOccupancy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.EventListElementOccupancy img {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* END for EVENT & EVENT TYPES - LIST ELEMENT */
