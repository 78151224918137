.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
  border-bottom: var(--yellow-border);
}
.Label {
  padding-top: 10px;
  font-size: var(--fs-small);
  color: var(--color-gray);
  text-transform: uppercase;
}
