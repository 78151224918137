.GoogleButton {
  color: #fff;
  height: 50px;
  width: 240px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  cursor: pointer;
}
.GoogleButton:hover .Text {
  background-color: var(--blue-dark);
}
.GoogleButton > .Logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GoogleButton > .Logo > img {
  width: 20px;
  height: 20px;
}
.GoogleButton > .Text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
}
