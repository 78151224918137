.List {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  width: 100%;
  margin: 10px 0;
}

.EventTypeCopyOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.EventTypeCopyOuter:hover > .EventTypeLink {
  text-decoration: underline;
}
.EventTypeListElement_Line_Links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}
.EventTypeLink {
  color: var(--blue-dark-link);
  text-transform: uppercase;
  font-size: var(--fs-small);
  padding: 4px 8px 2px 4px;
}
.IconCopy {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
}
.TableSchedule {
  width: 100%;
  border-collapse: collapse;
}
.TableSchedule tr {
  border-bottom: var(--gray-border-match-yellow);
}
.TableSchedule tr td:nth-child(1) {
  width: 90px;
}
.TableSchedule select {
  border-radius: 3px;
  padding: 5px;
}
.TableSchedule tr td:nth-child(1) > div {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-content: center;
}
.TableSchedule tr td:nth-child(2) {
  width: 100%;
}
.HoursLine {
  display: grid;
  grid-template-columns: 1fr 10px 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
}

.SvgTrash {
  width: 30px;
  height: 30px;
  padding: 7px;
  opacity: 0.5;
}
.SvgPlus {
  margin-top: 5px;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 2px;
  opacity: 0.6;
  border: var(--gray-border);
}

.RepeatListElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0;
}
.DummyInput {
  background-color: var(--background-color-inputs);
  margin: 4px 0 0 0;
  width: 100%;
  min-height: 40px;
  padding: 10px 14px;
  border: var(--gray-border-match-yellow);
  border-radius: var(--border-radius-big);
  line-height: 1.5;
  text-align: center;
}

.EditEventTypeOuter {
  padding: 54px 0 26px 0;
}

@media (min-width: 450px) {
  .EditEventTypeOuter {
    padding: 0;
  }
}
