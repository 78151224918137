.HandleOuter {
  padding: 32px 0;
}
.IconCopy {
  width: 20px;
  height: 20px;
}
.RowEndsWithCopyBtn {
  display: grid;
  grid-template-columns: 1fr 35px;
  gap: 26px;
  align-items: center;
}
